<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <TotalCard
          :table="'appointment_consultations'"
          :title="$t('chart.total_appointments')"
          :icon="'bx bx-buildings'"
        >
        </TotalCard>
        <TotalCard
          :table="'chat_consultations'"
          :title="$t('chart.total_chats')"
          :icon="'bx bx-chat'"
        >
        </TotalCard>
        <TotalCard
          :table="'video_consultations'"
          :title="$t('chart.total_videos')"
          :icon="'bx bx-video-recording'"
        >
        </TotalCard>
        <TotalCard
          :table="'voice_consultations'"
          :title="$t('chart.total_voices')"
          :icon="'bx bx-user-voice'"
        >
        </TotalCard>

        <TotalCard
          :table="'books'"
          :title="$t('chart.total_books')"
          :icon="'bx bx-book'"
        >
        </TotalCard>
        <TotalCard
          :table="'online_courses'"
          :title="$t('chart.total_online')"
          :icon="'bx bx-video-recording'"
        >
        </TotalCard>
        <TotalCard
          :table="'offline_courses'"
          :title="$t('chart.total_offline')"
          :icon="'bx bx-video-off'"
        >
        </TotalCard>
        <TotalCard
          :table="'meeting_conventions'"
          :title="$t('chart.total_meeting_conventions')"
          :icon="'bx bx-book'"
        >
        </TotalCard>
        <TotalCard
          :table="'diplomats'"
          :title="$t('chart.total_educational_program')"
          :icon="'bx bxs-graduation'"
        >
        </TotalCard>
        <TotalCard
          :table="'faqs'"
          :title="$t('chart.total_faqs')"
          :icon="'bx bx-comment'"
        >
        </TotalCard>
      </div>

      <div class="row">
        <LineChart :table="'users'"> </LineChart>

        <!-- <PieChart
                    :table="'countries'">
                </PieChart>   -->

        <!-- <BarChart
                    :table="'embassies'">
                </BarChart> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  components: {
    TotalCard: () => import("./TotalCard.vue"),
    LineChart: () => import("./LineChart.vue"),
    // PieChart: () => import('./PieChart.vue'),
    // BarChart: () => import('./BarChart.vue')
  },
  data() {
    return {
      //
      auth: {
        role: "",
      },
    };
  },
  mounted() {},
  watch: {
    //
  },
  created() {
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
  },
  methods: {
    //
  },
};
</script>
